import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import ApiService from "services/ApiService"
import Loader from "components/Loader"
import moment from "moment"
import Constants from "utils/Constants"
import CricketRegistration from "./Templates/Cricket"
import TennisRegistration from "./Templates/Tennis"
import PokerRegistration from "./Templates/Poker"
import RunRegistration from "./Templates/Run"
import VolleybalRegistration from "./Templates/Volleyball"
import BadmintonRegistration from "./Templates/Badminton"
import EditEvent from "./EditEvent"
import Alert from "components/Alert"
import Button from "components/Button"
import useApiHandler from "hooks/useApiHandler"

const EventRegistration = () => {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const { eventId } = useParams()

  const [event, setEvent] = useState(null)

  const eventApi = useApiHandler()
  const registerEventApi = useApiHandler()

  const [openEditEvent, setOpenEditEvent] = useState(false)

  const getEvent = useCallback(async () => {
    eventApi.request(
      {
        api: ApiService.getEventById,
        body: eventId,
        config: {
          displayError: false,
        },
      },
      (response, err) => {
        if (err) return

        setEvent(response.data.event)
      }
    )
  }, [eventApi, eventId])

  useEffect(() => {
    getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const registerEvent = async (values) => {
    try {
      let team = values["players"]
      delete values["players"]
      let registrationDetails = {
        ...values,
        team: team,
        event: event._id,
      }

      const body = {
        payment: {
          transactionType:
            values["registerThrough"] === "PAYPAL"
              ? Constants.EVENT_REGISTRATION_TRANSACTION_TYPE_PAYPAL
              : Constants.EVENT_REGISTRATION_TRANSACTION_TYPE_OFFLINE,
          amount: values.eventCost,
          intent: Constants.INTENT,
          currency: Constants.CURRENCY,
          paymentType: Constants.EVENT_REGISTRATION_PAYMENT_TYPE,
        },
        registration: registrationDetails,
      }

      registerEventApi.request(
        {
          api:
            values["registerThrough"] === "PAYPAL"
              ? ApiService.registerEventPaypal
              : ApiService.registerEventOffline,
          body: body,
          config: {
            displayError: true,
            continuousLoading: true,
          },
        },
        (response, err) => {
          if (err) return

          if (values["registerThrough"] === "PAYPAL") {
            localStorage.setItem("registrationDetails", JSON.stringify(registrationDetails))
            let link = response.data.url
            window.open(link, "_self")
          } else {
            navigate("/event-registration-offline")
          }
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  if (eventApi.loading || registerEventApi.loading) {
    return <Loader open={eventApi.loading || registerEventApi.loading} />
  }

  console.log(!eventApi.loading && eventApi.error && event?.eventType?.value !== 'badminton', eventApi)

  if (!eventApi.loading && eventApi.error && eventApi.event?.eventType?.value !== 'badminton') {
    if (eventApi.errorCode === "hold" || eventApi.errorCode === "reg_closed") {
      return (
        <div className="mx-auto container md:py-20">
          <Alert type="warning"> {eventApi.message} </Alert>
        </div>
      )
    }

    return (
      <div className="mx-auto container md:py-20">
        <Alert type="danger"> {eventApi.message} </Alert>
      </div>
    )
  }

  return (
    <div className="mx-auto container md:py-20">
      <EditEvent open={openEditEvent} setOpen={setOpenEditEvent} refreshEvent={getEvent} event={event} />

      {event && (
        <>
          <div className="flex flex-wrap">
            {event.flyer && (
              <div className="w-full lg:w-1/3">
                <img
                  src={ApiService.getMedia(event?.flyer)}
                  alt="Event"
                  className="w-full md:rounded-lg"
                />
              </div>
            )}
            <div className={`w-full lg:w-2/3 ${event.flyer ? "pl-5" : "md:pl-5"}`}>
              <h2 className="text-2xl font-medium mb-2"> {event.eventName} </h2>
              <p className="text-gray-500 font-medium text-lg mb-4">{event.eventDescription}</p>
              {!event.isHold && (
                <div className="flex flex-wrap my-4">
                  <div className="w-full">
                    <span className="font-bold">Start Date:</span>{" "}
                    <span>{moment.utc(event.eventStartDate).format("DD-MMM-YYYY")} </span>
                  </div>
                  <div className="w-full mt-2">
                    <span className="font-bold">End Date:</span>{" "}
                    <span>{moment.utc(event?.eventEndDate).format("DD-MMM-YYYY")} </span>
                  </div>
                </div>
              )}

              {event?.eventVenue && (
                <div className="w-full mt-2">
                  <span className="font-bold">Event Venue:</span>{" "}
                  <span>
                    {event.eventVenue}
                  </span>
                </div>
              )}

              {event?.geoLocation && (
                <div className="w-full mt-2">
                  <span className="font-bold">Geo Location:</span>{" "}
                  <span>
                    <a href={event.geoLocation}>{event.geoLocation}</a>
                  </span>
                </div>
              )}

              <div className="w-full mt-2">
                <span className="font-bold">Registration Fee:</span>{" "}
                <span>
                  {" "}
                  <RenderCost event={event} />{" "}
                </span>
              </div>

              {!event.isHold &&
                !event.isPrevious &&
                !event.isDisabled &&
                auth.isLoggedIn &&
                auth.isAdmin && (
                  <div className="mt-4">
                    <Button onClick={() => setOpenEditEvent(true)}>Update Event</Button>
                  </div>
                )}
            </div>
          </div>

          {event?.eventType?.value === 'badminton' && event.isHold && (
            <div class=" mt-4 flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
              <div>
                Thank you for your overwhelming support! We have closed registrations and wish everyone good luck for the Dhvani Pickleball Tournament.
              </div>
            </div>
          )}

          {event?.eventType?.value === 'tennis' && event.isHold && (
            <div class=" mt-4 flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
              <div>
                Thank you for your overwhelming support! We have closed registrations and wish everyone good luck for the Dhvani Tennis Tournament.
              </div>
            </div>
          )}

          {!event.isHold && !event.isPrevious && !event.isDisabled && (
            <div className="my-10 px-5 lg:px-0">
              <h2 className="mb-2 text-gray-500 font-medium text-lg"> {event.eventName} </h2>

              {["badminton"].includes(event?.eventType?.value) && (
                <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                  <div>
                    <span class="font-medium">Note: </span> All start times are subject to change, and events may be cancelled based on registration numbers. If we cancel a particular category, we will notify the affected players directly during the week of July 10th.
                    <br />
                    <br />
                    The detailed tournament format and rules will be provided to all registered players soon. Stay tuned for further communication and check back here regularly for updates.
                  </div>
                </div>
              )}

              {["cricket"].includes(event?.eventType?.value) && (
                <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                  <div>
                    <span class="font-medium">Note: </span> Register your team by August 10th to secure guaranteed team t-shirts. Design and color choices are available on a first-come, first-served basis. Take advantage of this opportunity now!
                    <br />
                    <br />
                    With overwhelming support, we have reached our maximum Men's and Kids under 17 team's count for now!  Registrations are closed for the moment. If there are any changes, we will let you know. Thank you for your understanding!
                  </div>
                </div>
              )}

              <div className="text-2xl text-gray-500 font-medium mb-2">REGISTER HERE:</div>

              {event.eventType?.value === "cricket" && (
                <CricketRegistration registerEvent={registerEvent} event={event} />
              )}
              {event.eventType?.value === "tennis" && (
                <TennisRegistration registerEvent={registerEvent} event={event} eventType="tennis" />
              )}
              {event.eventType?.value === "poker" && (
                <PokerRegistration registerEvent={registerEvent} event={event} />
              )}
              {event.eventType?.value === "run" && (
                <RunRegistration registerEvent={registerEvent} event={event} />
              )}
              {event.eventType?.value === "volleyball" && (
                <VolleybalRegistration registerEvent={registerEvent} event={event} />
              )}
              {event.eventType?.value === "badminton" && (
                <BadmintonRegistration registerEvent={registerEvent} event={event} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

const RenderCost = ({ event }) => {
  const [costs, setCosts] = useState([])

  useEffect(() => {
    if (event.eventCost) {
      let keys = Object.keys(event.eventCost)
      let values = Object.values(event.eventCost)

      let eventCosts = []
      for (let i = 0; i < keys.length; i++) {
        let keyNameArr = keys[i].split("-")
        let keyName = keyNameArr.join(" ")
        keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1)
        eventCosts.push({
          name: keyName,
          cost: values[i],
        })
      }

      setCosts(eventCosts)
    }
  }, [event])

  if (!event.eventCost["default"]) {
    return (
      <div>
        {costs.map((ec, idx) => (
          <div key={idx}>
            {" "}
            {ec.name} - ${ec.cost}{" "}
          </div>
        ))}
      </div>
    )
  } else {
    return <span> ${event.eventCost["default"]} </span>
  }
}

export default EventRegistration
